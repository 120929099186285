import { BoxProps } from '@mui/material'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid2'
import Typography from '@mui/material/Typography'
import React from 'react'
import { useIntl } from 'react-intl'

import AppStoreLogo from 'components/Illustration/AppStoreLogo'
import CapterraLogo from 'components/Illustration/CapterraLogo'
import FacebookLogo from 'components/Illustration/FacebookLogo'
import GoogleLogo from 'components/Illustration/GoogleLogo'
import GooglePlayLogo from 'components/Illustration/GooglePlayLogo'
import LoginPageIcon from 'components/Illustration/LoginPageIcon'
import TrustpilotLogo from 'components/Illustration/TrustpilotLogo'
import m from 'pages/Login/messages'
import { areExternalLinksVisible } from 'utils/areExternalLinksVisible'

function Link(props: BoxProps<'a'>) {
  return (
    <Box
      {...props}
      component="a"
      sx={(theme) => ({
        '&:hover': {
          borderColor: theme.palette.text.secondary,
        },
        alignItems: 'center',
        border: `1px solid ${theme.palette.grey['300']}`,
        borderRadius: '4px',
        cursor: 'pointer',
        display: 'flex',
        height: 80,
        justifyContent: 'center',
      })}
      target="_blank"
    />
  )
}

function InvestInSS() {
  const { formatMessage: f } = useIntl()

  return (
    <Grid
      alignItems="center"
      container
      direction="column"
      justifyContent="center"
      sx={{ height: '100%' }}
    >
      <LoginPageIcon sx={{ height: 190, mb: 8, width: 180 }} />

      <Typography
        align="center"
        sx={{
          maxWidth: 415,
          mb: 4,
        }}
        variant="h2"
      >
        {f(m.investInSSTitle)}
      </Typography>

      <Typography
        align="center"
        color="textSecondary"
        sx={{ maxWidth: 472, mb: 6 }}
      >
        {f(m.investInSSContent)}.
      </Typography>

      {areExternalLinksVisible && (
        <Grid container spacing={4} sx={{ maxWidth: 415 }}>
          <Grid size={6}>
            <Link href="https://www.trustpilot.com/evaluate/samesystem.com">
              <TrustpilotLogo />
            </Link>
          </Grid>
          <Grid size={6}>
            <Link href="https://reviews.capterra.com/new/192365">
              <CapterraLogo />
            </Link>
          </Grid>
          <Grid size={3}>
            <Link href="https://www.google.com/search?q=samesystem+denmark&rlz=1C1GCEU_enLT913LT913&oq=samesystem+denmark&aqs=chrome.0.69i59.3427j0j1&sourceid=chrome&ie=UTF-8#lrd=0x465259bef890dfa1:0x1c3b6afe7bb071c8,1">
              <GoogleLogo />
            </Link>
          </Grid>
          <Grid size={3}>
            <Link href="https://www.facebook.com/samesystem/reviews">
              <FacebookLogo />
            </Link>
          </Grid>
          <Grid size={3}>
            <Link href="https://apps.apple.com/gb/app/samesystem-check-in/id1357112032">
              <AppStoreLogo />
            </Link>
          </Grid>
          <Grid size={3}>
            <Link href="https://play.google.com/store/apps/details?id=com.samesystem.checkin">
              <GooglePlayLogo />
            </Link>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default InvestInSS
